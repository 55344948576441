import React, { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import { useMutation } from "@apollo/client";
import {
  UPDATE_EXECUTION_STAGE,
  CONVERT_TO_PAPERTRADE,
  CLOSE_EXECUTION_POSITION,
  UPDATE_SYNDICATION_ACCESS_CONTROL,
} from "graphql/mutations";
import {
  ID,
  ExecutionStage,
  ExecutionType,
  Exchange,
  CurrencyPair,
  ExecutionStatus,
  Syndication,
  Currency,
} from "graphql/schema";
import { useComposerStateContext } from "contexts/ComposerStateContext";
import { useExecutionContext } from "contexts/executions/ExecutionContext";
import { useNavigationContext } from "contexts/NavigationContext";
import { useAlertContext } from "contexts/AlertContext";
import { MAIN_PANEL_NAVIGATION_QUERY } from "pages/homepage/MainPanelNavigation";
import LinkButton from "core/forms/LinkButton";
import useConfirmationModal from "core/modal/useConfirmationModal";
import { logEvent } from "helpers/analytics";
import colors from "styles/colors";

type Props = Readonly<{
  id: ID;
  name?: string;
  stage?: ExecutionStage;
  type?: ExecutionType;
  exchange: Exchange;
  currencyPair: CurrencyPair;
  closeMenu?: () => void;
  isPack?: boolean;
  status: ExecutionStatus;
  isInsideBatch?: boolean;
  syndicationSettings?: Syndication;
  multiCoinCurrency?: Currency;
  className?: string;
  spanClassName?: string;
}>;

function ExecutionMenuOptions({
  id,
  name,
  stage,
  type,
  closeMenu,
  isPack = false,
  status,
  isInsideBatch = false,
  syndicationSettings,
  className,
  spanClassName,
}: Props) {
  const { listQuery } = useExecutionContext();
  const { onAddNewRun, onRemoveNewRun } = useNavigationContext();
  const { addError, setAlert } = useAlertContext();
  const { setUpdatedSyndication } = useComposerStateContext();

  const { t } = useTranslation();

  const [updateExecutionStage] = useMutation(UPDATE_EXECUTION_STAGE, {
    refetchQueries: [listQuery],
  });

  const [updateSyndicationAccessControl] = useMutation(
    UPDATE_SYNDICATION_ACCESS_CONTROL,
    {
      update: (_cache, { data }) => {
        if (!data) return;
        const { updateSyndicationAccessControl } = data;
        logEvent("UpdatedSyndicationVisibility");
        setUpdatedSyndication(updateSyndicationAccessControl.id);
      },
    },
  );

  const stages: ExecutionStage[] = useMemo(() => {
    switch (stage) {
      case "CANDIDATES":
        return [undefined, "RELEASE_CANDIDATES"];
      case "RELEASE_CANDIDATES":
        return ["CANDIDATES", "RELEASE_CANDIDATES"];
      default:
        return [undefined, "CANDIDATES"];
    }
  }, [stage]);

  const onDemoteClick = useCallback(
    (e) => {
      e.stopPropagation();
      updateExecutionStage({
        variables: { executionId: id, stage: stages[0] },
      }).catch((err) => {
        addError(t("error.demote_execution"));
      });
      onAddNewRun(stages[0], id);
      onRemoveNewRun(stage, id);
      if (closeMenu) closeMenu();
    },
    [
      id,
      stage,
      stages,
      updateExecutionStage,
      closeMenu,
      onAddNewRun,
      onRemoveNewRun,
      addError,
      t,
    ],
  );

  const confirm = useConfirmationModal();
  const [convertToPapertrade] = useMutation(CONVERT_TO_PAPERTRADE, {
    refetchQueries: [MAIN_PANEL_NAVIGATION_QUERY],
  });

  const unmonetizeClick = useCallback(() => {
    confirm(
      async () => {
        try {
          await convertToPapertrade({ variables: { syndicationId: id } });
        } catch (e) {
          addError(t("error.unmonetize"));
        }
      },
      <Trans
        i18nKey="message.execution_stop_monetizing_confirmation"
        values={{
          execution_name: name ?? "",
        }}
        components={[
          <span key="execution_stop_monetizing_confirmation"></span>,
        ]}
      />,
      t("terminal.stop_monetizing"),
    );
  }, [id, convertToPapertrade, addError, t, confirm, name]);

  const [closePosition] = useMutation(CLOSE_EXECUTION_POSITION);

  const closePositionClick = useCallback(() => {
    confirm(
      async () => {
        try {
          await closePosition({ variables: { id } });
          if (type === "LIVE") {
            setAlert({
              severity: "success",
              message: t("message.close_trade"),
            });
          }
        } catch (e) {
          addError(t("error.market_close"));
        }
      },
      t("message.close_position_confirmation"),
      t("terminal.market_close"),
    );
  }, [id, type, closePosition, setAlert, addError, t, confirm]);

  const updateSyndicationAccessControlClick = useCallback(() => {
    try {
      confirm(
        async () =>
          await updateSyndicationAccessControl({
            variables: {
              id,
              accessControl:
                syndicationSettings?.accessControl === "USE_ALLOW_LIST"
                  ? "ALLOW_ALL_USERS"
                  : "USE_ALLOW_LIST",
            },
          }),
        syndicationSettings?.accessControl === "USE_ALLOW_LIST"
          ? t("message.make_bot_public_confirmation")
          : t("message.make_bot_private_confirmation"),
        syndicationSettings?.accessControl === "USE_ALLOW_LIST"
          ? t("terminal.make_public")
          : t("terminal.make_private_and_use_allow_list"),
      );
    } catch (e) {
      addError(t("error.failed_to_update_bot_visibility"));
    }
  }, [
    confirm,
    updateSyndicationAccessControl,
    addError,
    id,
    syndicationSettings,
    t,
  ]);

  return (
    <>
      {(stage === "CANDIDATES" || stage === "RELEASE_CANDIDATES") &&
        type !== "SYNDICATION" &&
        !isInsideBatch && (
          <LinkButton onClick={onDemoteClick} className={className}>
            <span className={spanClassName ?? "action-option"}>
              {t("terminal.demote")}
            </span>
          </LinkButton>
        )}
      {type === "PAPERTRADE" && isPack && (
        <LinkButton className={css(styles.disabledButton)}>
          <span className={spanClassName ?? "action-option"}>
            {t("terminal.monetize_beta")}
          </span>
        </LinkButton>
      )}
      {type === "SYNDICATION" && (
        <LinkButton
          onClick={updateSyndicationAccessControlClick}
          className={className}
        >
          <span className={spanClassName ?? "action-option"}>
            {syndicationSettings?.accessControl === "USE_ALLOW_LIST"
              ? t("terminal.make_public")
              : t("terminal.make_private_and_use_allow_list")}
          </span>
        </LinkButton>
      )}
      {type === "SYNDICATION" && (
        <LinkButton onClick={unmonetizeClick} className={className}>
          <span className={spanClassName ?? "action-option"}>
            {t("terminal.stop_monetizing")}
          </span>
        </LinkButton>
      )}
      {(type === "PAPERTRADE" || type === "LIVE") && status === "RUNNING" && (
        <LinkButton onClick={closePositionClick} className={className}>
          <span className={spanClassName ?? "action-option"}>
            {t("terminal.market_close_position")}
          </span>
        </LinkButton>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  disabledButton: {
    width: "100%",
    textAlign: "left",
    paddingTop: "2px",
    paddingBottom: "0px",
    fontWeight: 500,
    fontSize: "12px",
    userSelect: "none",
    cursor: "default",
    color: colors.steel,
  },
});

export default React.memo(ExecutionMenuOptions);
