import * as yup from "yup";
import { ScriptParameterDetails, ID } from "../graphql/schema";

export const RESOLUTION_REGEX = /^(\d+|\d+(D|W|H)|(D|W|H))$/;

// -0.432 or 3,4,5.0 or -1_1:0.5
export const DECIMAL_MVT_REGEX =
  /^(-?\d*\.?\d+|\.\d+)(( *, *(-?\d*\.?\d+|\.\d+))*| *_ *(-?\d*\.?\d+|\.\d+) *: *(\d*\.?\d+|\.\d+))$/i;

// 0 to 100 including decimals
export const PERCENTAGE_MVT_REGEX =
  /^((([0-9]|[0-9][0-9])(\.\d+)?)|100)(( *, *((([0-9]|[0-9][0-9])(\.\d+)?)|100))*| *_ *((([0-9]|[0-9][0-9])(\.\d+)?)|100) *: *(\d+\.?\d{0,9}|\.\d{1,9}))$/i;

export const INTEGER_MVT_REGEX =
  /^(-?\d+)(( *, *-?\d+)*| *_ *(-?\d+) *: *(\d+))$/i;

export const RESOLUTION_MVT_REGEX =
  /^(\d+|\d*(D|W|H)) *((, *(\d+|\d*(D|W|H)))*|_ *(\d+|\d*(D|W|H)) *: *(\d+|\d*(D|W|H)))$/;

// to find the largest resolution for the lookback days
export const RESOLUTION_MVT_COMMA_REGEX =
  /^(\d+|\d*(D|W|H)) *(, *(\d+|\d*(D|W|H)))+$/;
export const RESOLUTION_MVT_RANGE_REGEX =
  /^(\d+|\d*(D|W|H)) *(_ *(\d+|\d*(D|W|H))) *: *(\d+|\d*(D|W|H))$/;

export const PASSWORD_REGEX = /^(?=.*\d)(?=.*\w).{8,32}$/;

export const NAME_REGEX = /^([a-z,A-Z,0-9,.]*)$/;

export const URL_REGEX =
  /^([a-z,A-Z,0-9]+.)?([a-z,A-Z,0-9]+\.)([a-z,A-Z,0-9]+)(\/.*)?$/;

export const IMAGE_REGEX = /^(image\/.*)$/i;

export const DATE_TIME_REGEX = /^(\d\d\d\d-\d\d-\d\dT\d\d:\d\d)$/i;

export const REFERRAL_ID_REGEX = /^([a-z,A-Z,0-9,--,_]+)$/i;

export function buildAlgorithmSettingsSchema(
  parameters: ScriptParameterDetails[],
  isMvt?: boolean,
): yup.Schema<any> {
  const algoSettingsShape: yup.ObjectSchemaDefinition<any> = parameters.reduce(
    (acc, param) => {
      let yupType: yup.MixedSchema<any>;
      const label = param.readableName || param.fieldName;

      switch (param.valueType) {
        case "DECIMAL":
          yupType = isMvt
            ? yup
                .string()
                .matches(
                  DECIMAL_MVT_REGEX,
                  `${label} can be a single decimal, comma separated decimals or from_to:increment.`,
                )
            : yup
                .number()
                // eslint-disable-next-line
                .typeError("${label} must be a decimal value.")
                .default(parseFloat(param.defaultValue));

          if (param.options && !isMvt) {
            yupType = yupType.oneOf(param.options.map((x) => parseFloat(x)));
          }
          break;
        case "INTEGER":
          yupType = isMvt
            ? yup
                .string()
                .matches(
                  INTEGER_MVT_REGEX,
                  `${label} can be a single integer, comma separated integers or from_to:increment.`,
                )
            : yup
                .number()
                .integer()
                // eslint-disable-next-line
                .typeError("${label} must be an integer value.")
                .default(parseInt(param.defaultValue));

          if (param.options && !isMvt) {
            yupType = yupType.oneOf(param.options.map((x) => parseInt(x)));
          }
          break;
        case "BOOLEAN":
          yupType = isMvt
            ? yup.string()
            : yup.boolean().default(JSON.parse(param.defaultValue));

          if (param.options && !isMvt) {
            yupType = yupType.oneOf(param.options.map((x) => JSON.parse(x)));
          }
          break;
        case "RESOLUTION":
          yupType = isMvt
            ? yup.string().matches(
                RESOLUTION_MVT_REGEX,
                // eslint-disable-next-line
                "${label} can be a single resolution, comma separated values or from_to:increment.",
              )
            : yup
                .string()
                .matches(RESOLUTION_REGEX)
                // eslint-disable-next-line
                .typeError("${label} must be a valid resolution.")
                .default(param.defaultValue);

          if (param.options && !isMvt) {
            yupType = yupType.oneOf(param.options);
          }
          break;
        // TODO: Add other parameter types as we support them
        default:
          yupType = yup.string().default(param.defaultValue);

          if (param.options && !isMvt) {
            yupType = yupType.oneOf(param.options);
          }
      }

      acc[param.fieldName] = yupType.label(label).required();
      return acc;
    },
    {} as yup.ObjectSchemaDefinition<any>,
  );

  return yup.object().shape(algoSettingsShape);
}

export function detailsToScript(details: any): any {
  return {
    id: details.scriptId,
    language: details.language,
    name: details.name,
    latestValid: {
      id: details.revisionId,
      isValid: true,
      body: "",
      parameters: details.parameters,
      __typename: "ScriptRevision",
    },
    __typename: "Script",
  };
}

export function buildStrategyPackExecutionsSchema(
  executions: ID[],
): yup.Schema<any> {
  const executionsShape: yup.ObjectSchemaDefinition<any> = executions.reduce(
    (acc, execution) => {
      // used underscore so it doesn't convert to an array with up to the the number of the max id value
      acc[`${execution}_`] = yup
        .number()
        .max(100, "Max % is 100")
        .min(0, "Min % is 0")
        // eslint-disable-next-line
        .typeError("Enter a valid number")
        .required("% required");
      return acc;
    },
    {} as yup.ObjectSchemaDefinition<any>,
  );

  return yup.object().shape(executionsShape);
}
