import React, { createContext, useState, useCallback, useContext } from "react";
import invariant from "invariant";
import { Color } from "@material-ui/lab";
import { logEvent } from "../helpers/analytics";

export type AddErrorFn = (error: string) => void;

export interface AlertInfo {
  message: string;
  severity: Color | undefined;
  action?: React.ReactNode;
}

interface Context {
  errors: string[] | undefined;
  setErrors: (errors: string[] | undefined) => void;
  addError: AddErrorFn;
  alert: AlertInfo | undefined;
  setAlert: (alert: AlertInfo | undefined) => void;
}

export const AlertContext = createContext<Context | undefined>(undefined);

export function useAlertContext() {
  const context = useContext(AlertContext);
  invariant(
    context != null,
    "Component is not a child of AlertContext provider",
  );
  return context;
}

export function AlertContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const [alert, setAlert] = useState<AlertInfo | undefined>(undefined);

  const addError = useCallback(
    (error: string) => {
      logEvent("Error", { error });
      if (!errors?.includes(error)) {
        const updatedErrors = errors?.concat(error) ?? [error];
        setErrors(updatedErrors);
      }
    },
    [errors, setErrors],
  );

  return (
    <AlertContext.Provider
      value={{ errors, setErrors, addError, alert, setAlert }}
    >
      {children}
    </AlertContext.Provider>
  );
}
