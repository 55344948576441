import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import invariant from "invariant";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import queryString from "query-string";
import { PreloadedScript } from "__generated__/graphql";
import { ID, ExecutionType, Execution } from "../graphql/schema";
import { EMPTY_OBJ, useMultiSelectContext } from "./MultiSelectContext";
import { useChartControlsContext } from "./charts/ChartControlsContext";
import { useBoolean } from "../helpers/hooks";
import { logEvent } from "../helpers/analytics";
import {
  BATCHTEST_SUBTAB,
  CANDIDATES_SUBTAB,
  EXPERIMENTS_SUBTAB,
  LIVE_BOTS_SUBTAB,
  LIVE_SETUPS_SUBTAB,
  RELEASE_CANDIDATES_SUBTAB,
  SETUPS_TAB,
} from "helpers/navigation";
import { getFirstString } from "helpers/urlParameters";
import { fromStringValue } from "helpers/strings";

interface SelectedRun {
  type: "execution" | "mvt" | "strategyPack" | "subscription" | "syndication";
  id: ID;
  mvtId?: ID;
  isPackExecution?: boolean;
  toLiveCopy?: boolean;
  strategySettingsField?: string; // to go to the selected strategy settings field
}

interface Context {
  isDisplayed: boolean;
  show: () => void;
  hide: () => void;
  displayForExecution: (
    executionID: ID,
    isPackExecution?: boolean,
    toLiveCopy?: boolean,
  ) => void;
  displayForMVT: (mvtID: ID, strategySettingsField?: string) => void;
  displayForPreview: (automatic?: boolean) => void;
  displayForPack: (executionID: ID, toLiveCopy?: boolean) => void;
  displayForSubscription: (id: ID) => void;
  displayForSyndication: (id: ID) => void;
  selected?: SelectedRun;
  creationType?: ExecutionType;
  setCreationType: (type?: ExecutionType) => void;
  clearSelected: () => void;
  openLiveCreation: () => void;
  openBacktestCreation: () => void;
  openPaperTradeCreation: () => void;
  openStrategyPacksCreation: (execution?: Execution) => void;
  openSyndicationCreation: () => void;
  isCopied: boolean;
  setCopied: (isCopied: boolean) => void;
  updatedSyndication: ID | undefined;
  setUpdatedSyndication: (syndicationId: ID | undefined) => void;
  updatedSyndicationWithAllowList: ID | undefined;
  setUpdatedSyndicationWithAllowList: (syndicationId: ID | undefined) => void;
  isMultiCoinPack: boolean;
  setIsMultiCoinPack: () => void;
  setIsNotMultiCoinPack: () => void;
  initialPackExecution: Execution | undefined;
  setInitialPackExecution: (ex: Execution | undefined) => void;
  preloadedScript: PreloadedScript | undefined;
  setPreloadedScript: (type: PreloadedScript | undefined) => void;
  onOpenSetupComposer: (preloadedScript: PreloadedScript) => void;
  isPreloadedScriptUrlParam: boolean;
  setIsPreloadedScriptUrlParam: (isUrlParam: boolean) => void;
}

const ComposerStateContext = createContext<undefined | Context>(undefined);

export function useComposerStateContext() {
  const context = useContext(ComposerStateContext);
  invariant(
    context != null,
    "Component is not a child of ComposerStateContext provider",
  );
  return context;
}

interface Props {
  children: ReactNode;
}

export function ComposerStateContextProvider({ children }: Props) {
  const history = useHistory();
  const location = useLocation();
  // to set the composer to be open by default if there is a preloaded script search param
  const preloadedScriptParam = getFirstString(
    queryString.parse(location.search)["preloadedScript"],
  );

  const { updateCheckedForPack } = useMultiSelectContext();
  const { clearChartExecution } = useChartControlsContext();

  const [isDisplayed, show, hide] = useBoolean(!!preloadedScriptParam);
  const [selected, setSelected] = useState<SelectedRun | undefined>(undefined);
  const [creationType, setCreationType] = useState<ExecutionType | undefined>(
    undefined,
  );
  const [isCopied, setCopied] = useState(false);
  // to differentiate between coming from url params or from card entry point
  const [isPreloadedScriptUrlParam, setIsPreloadedScriptUrlParam] = useState(
    !!fromStringValue(PreloadedScript, preloadedScriptParam ?? ""),
  );
  const [preloadedScript, setPreloadedScript] = useState<
    PreloadedScript | undefined
  >(fromStringValue(PreloadedScript, preloadedScriptParam ?? ""));

  useEffect(() => {
    if (preloadedScriptParam && preloadedScript) {
      history.replace(`/${SETUPS_TAB}${location.search}`);
      logEvent("OpenedSetupComposerFromUrlParameter", {
        preloadedScript: preloadedScript,
        referral:
          getFirstString(queryString.parse(location.search)["fpr"]) ?? "",
      });
    }
    /*eslint-disable react-hooks/exhaustive-deps*/
  }, []);

  // to refresh an individual syndication after it has been updated
  const [updatedSyndication, setUpdatedSyndication] = useState<ID | undefined>(
    undefined,
  );
  // to open the allow list modal for an individual syndication after it has been created with an allow list
  const [updatedSyndicationWithAllowList, setUpdatedSyndicationWithAllowList] =
    useState<ID | undefined>(undefined);

  const [isMultiCoinPack, setIsMultiCoinPack, setIsNotMultiCoinPack] =
    useBoolean(false);

  const [initialPackExecution, setInitialPackExecution] = useState<Execution>();

  const execution = useRouteMatch<{ executionId?: ID }>({
    path: `/(${LIVE_BOTS_SUBTAB}|${LIVE_SETUPS_SUBTAB}|${EXPERIMENTS_SUBTAB}|${CANDIDATES_SUBTAB}|${RELEASE_CANDIDATES_SUBTAB})/:executionId?`,
  });

  const mvtExecution = useRouteMatch<{ executionId?: ID }>({
    path: `/${BATCHTEST_SUBTAB}/:multivariantId([0-9]+)/:executionId([0-9]+)?`,
  });

  const mvt = useRouteMatch<{ mvtId?: ID }>({
    path: `/${BATCHTEST_SUBTAB}/:mvtId([0-9]+)?`,
  });

  const selectedExecutionId =
    mvtExecution?.params?.executionId ?? execution?.params?.executionId;
  const selectedMvtID = mvt?.params?.mvtId;

  const displayForExecution = useCallback(
    (id: ID, isPackExecution?: boolean, toLiveCopy?: boolean) => {
      show();
      setPreloadedScript(undefined);
      setIsPreloadedScriptUrlParam(false);
      setCreationType("PAPERTRADE");
      setSelected({
        id,
        type: "execution",
        mvtId: selectedMvtID,
        isPackExecution,
        toLiveCopy,
      });
    },
    [show, selectedMvtID],
  );

  useEffect(() => {
    if (selectedExecutionId) {
      setPreloadedScript(undefined);
      setIsPreloadedScriptUrlParam(false);
      setSelected({
        id: selectedExecutionId,
        type: "execution",
        mvtId: selectedMvtID,
      });
    }
  }, [selectedExecutionId, selectedMvtID]);

  useEffect(() => {
    if (selectedMvtID) {
      setSelected({ id: selectedMvtID, type: "mvt" });
      setPreloadedScript(undefined);
      setIsPreloadedScriptUrlParam(false);
    }
  }, [selectedMvtID]);

  const displayForMVT = useCallback(
    (id: ID, strategySettingsField?: string) => {
      show();
      setSelected({ id, type: "mvt", strategySettingsField });
      setPreloadedScript(undefined);
    },
    [show],
  );

  const displayForPreview = useCallback(
    (automatic?: boolean) => {
      setSelected(undefined);
      setCreationType("PREVIEW");
      show();
      setPreloadedScript(undefined);
      setIsPreloadedScriptUrlParam(false);
      if (!automatic) {
        logEvent("ClickedEditorPreviewSettingsIcon");
      }
    },
    [show],
  );

  const displayForPack = useCallback(
    (id: ID, toLiveCopy?: boolean) => {
      show();
      setSelected({ id, type: "strategyPack", toLiveCopy });
      setPreloadedScript(undefined);
      setIsPreloadedScriptUrlParam(false);
    },
    [show],
  );

  const displayForSubscription = useCallback(
    (id: ID) => {
      show();
      setSelected({ id, type: "subscription" });
      setPreloadedScript(undefined);
      setIsPreloadedScriptUrlParam(false);
    },
    [show],
  );

  const displayForSyndication = useCallback(
    (id: ID) => {
      show();
      setSelected({ id, type: "syndication" });
      setPreloadedScript(undefined);
      setIsPreloadedScriptUrlParam(false);
    },
    [show],
  );

  const openSyndicationCreation = useCallback(() => {
    setSelected(undefined);
    setCreationType("SYNDICATION");
    show();
    setPreloadedScript(undefined);
    setIsPreloadedScriptUrlParam(false);
  }, [show]);

  const openBacktestCreation = useCallback(() => {
    setSelected(undefined);
    setCreationType("BACKTEST");
    show();
    setPreloadedScript(undefined);
    setIsPreloadedScriptUrlParam(false);
  }, [show]);

  const openPaperTradeCreation = useCallback(() => {
    setSelected(undefined);
    setCreationType("PAPERTRADE");
    show();
    setPreloadedScript(undefined);
    setIsPreloadedScriptUrlParam(false);
  }, [show]);

  const openLiveCreation = useCallback(() => {
    setSelected(undefined);
    setCreationType("LIVE");
    show();
    setPreloadedScript(undefined);
    setIsPreloadedScriptUrlParam(false);
  }, [show]);

  const openStrategyPacksCreation = useCallback(
    (execution?: Execution) => {
      if (execution) {
        setInitialPackExecution(execution);
      }

      updateCheckedForPack(
        execution?.id
          ? {
              [execution.id]: {
                id: execution.id,
                exchange: execution.exchange,
                currencyPair: execution.currencyPair,
                multiCoinPackCurrency: execution.multiCoinCurrency,
                status: execution.status,
                leverage: execution.leverage,
                leverageShort: execution.leverageShort,
              },
            }
          : EMPTY_OBJ,
      );
      setSelected(undefined);
      setCreationType("STRATEGY_PACKS");
      show();
      setIsNotMultiCoinPack();
      setPreloadedScript(undefined);
      setIsPreloadedScriptUrlParam(false);
    },
    [updateCheckedForPack, show, setIsNotMultiCoinPack],
  );

  const onOpenSetupComposer = useCallback(
    (preloadedScript: PreloadedScript) => {
      clearChartExecution();
      setPreloadedScript(preloadedScript);
      setIsPreloadedScriptUrlParam(false);
      setSelected(undefined);
      setCreationType("LIVE");
      show();
      logEvent("OpenedSetupComposerFromPowerbarCard", { preloadedScript });
    },
    [],
  );

  const clearSelected = useCallback(() => setSelected(undefined), []);

  return (
    <ComposerStateContext.Provider
      value={{
        show,
        openBacktestCreation,
        openPaperTradeCreation,
        openLiveCreation,
        creationType,
        setCreationType,
        isDisplayed,
        clearSelected,
        selected,
        hide,
        displayForExecution,
        displayForMVT,
        displayForPreview,
        displayForPack,
        displayForSubscription,
        displayForSyndication,
        openSyndicationCreation,
        openStrategyPacksCreation,
        isCopied,
        setCopied,
        updatedSyndication,
        setUpdatedSyndication,
        updatedSyndicationWithAllowList,
        setUpdatedSyndicationWithAllowList,
        isMultiCoinPack,
        setIsMultiCoinPack,
        setIsNotMultiCoinPack,
        initialPackExecution,
        setInitialPackExecution,
        preloadedScript,
        setPreloadedScript,
        onOpenSetupComposer,
        isPreloadedScriptUrlParam,
        setIsPreloadedScriptUrlParam,
      }}
    >
      {children}
    </ComposerStateContext.Provider>
  );
}

export default ComposerStateContext;
