import React from "react";
import { useUserContext } from "contexts/UserContext";
import { useAnnouncementContext } from "contexts/AnnouncementContext";
import "./userstatusbanner.scss";

function FreeTierBanner() {
  return null;
  // return (
  //   <div className="user-status-banner">
  //     <Trans
  //       i18nKey="message.currently_on_free_version"
  //       components={[
  //         <span key="currently_on_free_version"></span>,
  //         <button
  //           key="currently_on_free_version_button"
  //           onClick={onUpgradeClick}
  //         ></button>,
  //       ]}
  //     />
  //   </div>
  // );
}

function UserStatusBanner() {
  const { user } = useUserContext();
  const { isActive } = useAnnouncementContext();

  if (isActive || (user?.outstandingInvoiceCount ?? 0) > 0) return null;

  switch (true) {
    // case user?.revokedSub === true:
    //     return <SubRevokedBanner />;
    // case user?.subscription?.status === 'EXPIRED' || user?.unpaidInvoices === true:
    //     return <SubExpiredBanner />;
    case user?.tier === "FREE":
      return <FreeTierBanner />;
    default:
      return null;
  }
}

export default React.memo(UserStatusBanner);
