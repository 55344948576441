import {
  RESOLUTION_MVT_COMMA_REGEX,
  RESOLUTION_MVT_RANGE_REGEX,
} from "./algorithmUtils";

const MINS_IN_DAY = 60 * 24;

export const LOOKBACK_PERIOD_BARS = 500;

// A user can still manually enter a larger lookback, but this is the max we will suggest
export const MAX_DEFAULT_LOOKBACK_DAYS = 50;

/**
 * Given a resolution string, return the number of minutes in the specified resolution.
 * @param resolution The resolution to parse
 * @return The number of minutes in the specified resolution
 */
export function resolutionToMins(resolution: string): number {
  let unit = resolution[resolution.length - 1];
  let multiplier = 1;

  switch (unit) {
    case "M":
      multiplier = 60 * 24 * 30; // For now this shouldn't even happen!
      break;
    case "h":
    case "H":
      multiplier = 60;
      break;
    case "D":
      multiplier = 60 * 24;
      break;
    case "W":
      multiplier = 60 * 24 * 7;
      break;
  }

  let base = parseInt(resolution);
  if (isNaN(base)) base = 1;
  return base * multiplier;
}

/**
 * Given a resolution string and number of bars, determine the number of days needed for a lookback period with that many days.
 * @param resolution The resolution string to derive minutes from
 * @param numBars The number of bars in the requested lookback period
 * @return The number of days required to get `numBars` in `resolution` (rounded up to the nearest day)
 */
export function lookbackDaysForResolution(
  resolution: string,
  numBars: number,
): number {
  if (resolution.match(RESOLUTION_MVT_RANGE_REGEX)) {
    resolution = resolution.substring(
      resolution.indexOf("_") + 1,
      resolution.indexOf(":"),
    );
  } else if (resolution.match(RESOLUTION_MVT_COMMA_REGEX)) {
    const sizes = resolution.split(",");
    let maxMinutes = 1;
    sizes.forEach((size: string) => {
      const resMinutes = resolutionToMins(size.trim());
      if (resMinutes > maxMinutes) maxMinutes = resMinutes;
    });

    // Then determine the total minutes required to get `numBars`
    const lookbackTime = numBars * maxMinutes;

    // Then round that time to the nearest day
    return Math.ceil(lookbackTime / MINS_IN_DAY);
  }

  // First get the number of minutes in the given resolution
  const resMinutes = resolutionToMins(resolution.toString().trim());

  // Then determine the total minutes required to get `numBars`
  const lookbackTime = numBars * resMinutes;

  // Then round that time to the nearest day
  return Math.ceil(lookbackTime / MINS_IN_DAY);
}

export function getSmallestResolutionInMinutes(resolution: string): number {
  if (resolution?.includes(",")) {
    const sizes = resolution.split(",");
    let smallestSize: number | undefined = undefined;
    for (const size of sizes) {
      const resolution = resolutionToMins(size.trim());
      if (!smallestSize || smallestSize > resolution) {
        smallestSize = resolution;
      }
    }

    return smallestSize ?? 1;
  } else if (resolution?.includes("_") && resolution?.includes(":")) {
    const underscoreIndex = resolution.indexOf("_");
    const firstNumber = resolutionToMins(
      resolution.substring(0, underscoreIndex).trim(),
    );
    const secondNumber = resolutionToMins(
      resolution.substring(underscoreIndex + 1, resolution.indexOf(":")).trim(),
    );

    return Math.min(firstNumber, secondNumber);
  } else {
    return resolutionToMins(resolution);
  }
}

export function dateFromSmallestResolution(
  resolution: string,
  maxCandlesAmount: number,
  updateEndDate: boolean,
  startDate?: Date,
  endDate?: Date,
): string | undefined {
  const totalCandlesInMiliseconds =
    getSmallestResolutionInMinutes(resolution) * 60000 * maxCandlesAmount;

  if (updateEndDate && startDate) {
    const latestTimeByTotalCandles =
      startDate.getTime() + totalCandlesInMiliseconds + MINS_IN_DAY * 60000;

    if (!endDate || (endDate && endDate.getTime() > latestTimeByTotalCandles)) {
      return new Date(latestTimeByTotalCandles).toISOString().substr(0, 10);
    }
  } else if (!updateEndDate && endDate) {
    const earliestTimeByTotalCandles =
      endDate.getTime() - totalCandlesInMiliseconds;

    if (
      !startDate ||
      (startDate && startDate.getTime() < earliestTimeByTotalCandles)
    ) {
      return new Date(earliestTimeByTotalCandles).toISOString().substr(0, 10);
    }
  }
}

export function getTotalCandlesAmount(
  resolution: string,
  startDate: Date,
  endDate: Date,
): number {
  const smallestCandleInMiliseconds =
    getSmallestResolutionInMinutes(resolution) * 60000;
  const milisecondsDifference = endDate.getTime() - startDate.getTime();

  return Math.round(milisecondsDifference / smallestCandleInMiliseconds);
}
