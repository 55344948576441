import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { History } from "history";
import {
  SidebarPage,
  useAccountSettingsContext,
} from "contexts/AccountSettingsContext";
import SettingsSidebar from "./SettingsSidebar";
import {
  ACCOUNT_BILLING_TAB,
  ACCOUNT_EMAIL_TAB,
  ACCOUNT_EXCHANGES_TAB,
  ACCOUNT_REFERRALS_TAB,
  ACCOUNT_SECURITY_TAB,
  ACCOUNT_SUBSCRIPTIONS_TAB,
  ACCOUNT_TAB,
  goToAccountDashboard,
} from "helpers/navigation";
import { setTitle } from "helpers/environment";
import "./settings.scss";

// Currently we only want to see the billing pages in development builds
const SIDEBAR_ITEMS: SidebarPage[] = [
  "general",
  // "exchanges",
  // "billing",
  "security",
  // "subscriptions",
  // "email_preferences",
  // "referrals",
];

// to remember the last seen page in the account settings page if navigated away from it
export const ACCOUNT_SETTINGS_URL_STORAGE_KEY = "account-settings-url";

export function getLinkFromPage(page: SidebarPage) {
  switch (page) {
    case "general":
      return ACCOUNT_TAB;
    case "exchanges":
      return ACCOUNT_EXCHANGES_TAB;
    case "billing":
      return ACCOUNT_BILLING_TAB;
    case "security":
      return ACCOUNT_SECURITY_TAB;
    case "subscriptions":
      return ACCOUNT_SUBSCRIPTIONS_TAB;
    case "email_preferences":
      return ACCOUNT_EMAIL_TAB;
    case "referrals":
      return ACCOUNT_REFERRALS_TAB;
  }
}

type Props = Readonly<{
  page: SidebarPage;
  history: History;
  children: React.ReactNode;
}>;

function AccountSettings({ page, history, children }: Props) {
  const { t } = useTranslation();

  const { setInnerPage } = useAccountSettingsContext();

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = useCallback(
    () => setCollapsed(!collapsed),
    [setCollapsed, collapsed],
  );

  const onItemClick = useCallback(
    (item: SidebarPage) => {
      setCollapsed(true);
      setInnerPage("");
      goToAccountDashboard(getLinkFromPage(item), history, true);
    },
    [history, setCollapsed, setInnerPage],
  );

  useEffect(() => {
    switch (page) {
      case "general":
        setTitle(t("terminal.account"));
        break;
      case "exchanges":
        setTitle(t("terminal.account_exchanges"));
        break;
      case "billing":
        setTitle(t("terminal.account_billing"));
        break;
      case "security":
        setTitle(t("terminal.account_security"));
        break;
      case "subscriptions":
        setTitle(t("terminal.account_subscriptions"));
        break;
      case "email_preferences":
        setTitle(t("terminal.account_email"));
        break;
      case "referrals":
        setTitle(t("terminal.account_referrals"));
        break;
    }
  }, [page, t]);

  return (
    <div className="settings-page">
      <SettingsSidebar
        sidebarItems={SIDEBAR_ITEMS}
        page={page}
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        onItemClick={onItemClick}
      />
      {children}
    </div>
  );
}

export default AccountSettings;
