import gql from "graphql-tag";
import {
  User,
  Execution,
  Credential,
  ScriptRevision,
  Script,
  Multivariant,
  ID,
  ExecutionFilter,
  ExecutionSort,
  SyndicationUpdatesConnection,
  SyndicationUpdate,
  PublicUser,
  PublicSyndication,
  PageQuery,
  UserFilter,
  Tag,
  ColumnsPreset,
  PortfolioDailyLog,
  Announcement,
  SortDirection,
  CountData,
  GettingStarted,
  FilterPreset,
  MostPopularCreatorsConnection,
  MostPopularSyndicationsConnection,
  ExecutionFieldComparison,
  ScriptListDetails,
  SharedExecution,
  Exchange,
  PortfolioRealizedProfitsLog,
  CurrencyPairDetails,
  Analysis,
  VerifyRecaptcha,
  ScriptLanguage,
} from "../schema";
import {
  SCRIPT_REVISION_DATA,
  SCRIPT_MAIN_DATA,
  SCRIPT_LIST_DATA,
  EXECUTION_CONTROL_DATA,
  EXECUTION_LIST_DATA,
  EXECUTION_SUBSCRIPTION_DATA,
  USER_DATA,
  CREDENTIAL_DATA,
  EXECUTION_DETAILS_DATA,
  SCRIPTREVISION_FULL_DATA,
  SCRIPTREVISION_DATE_DATA,
  CURRENCYPAIRDETAILS_DATA,
  ASSET_DATA,
  ORDERRULES_DATA,
  ExecutionControlDataFields,
  ExecutionDetailsDataFields,
  ScriptRevisionDataFields,
  ScriptMainData,
  ScriptRevisionFullData,
  ScriptListData,
  MULTIVARIANT_LIST_DATA,
  MULTIVARIANT_EXECUTIONS_DATA,
  MULTIVARIANT_DETAILS_DATA,
  MULTIVARIANT_COMPOSER_DATA,
  TRADE_DATA,
  SHARED_EXECUTION_DATA,
  TRADES_MEASUREMENT_DATA,
  ScriptWithParameters,
  EXECUTION_LOG_DATA,
  EXECUTION_PREVIEW_DATA,
  PUBLIC_SYNDICATION_DATA,
  PUBLIC_SYNDICATION_DETAILS_DATA,
  PublicSyndicationListData,
  PUBLIC_SYNDICATION_LIST_DATA,
  ANALYSIS,
  SYNDICATION_UPDATE,
  ORDER_MAIN_DATA,
  ORDER_PRE_COMMISSION_DATA,
  ORDER_POST_COMMISSION_DATA,
  PackExecutionDataFields,
  USER_PROMOTER_DATA,
} from "../fragments";
import { PartialMultivariant } from "../mutations";
import { PreloadedScript } from "__generated__/graphql";

// ME (USER)

export const GET_MY_DETAILS = gql`
  query GetMyDetails {
    me {
      ...UserData
    }
  }
  ${USER_DATA}
`;

export type GetMyDetailsData = {
  me?: User;
};

// EXCHANGES

export const GET_EXCHANGE_DETAILS = gql`
  query GetExchangeDetails {
    exchanges {
      id
      currencyPairs {
        ...CurrencyPairDetailsData
        orderRules {
          ...OrderRulesData
        }
      }
      isMargin
    }
  }
  ${CURRENCYPAIRDETAILS_DATA}
  ${ORDERRULES_DATA}
`;

// SCRIPTS

export const GET_SCRIPTS = gql`
  query GetScripts {
    scripts {
      ...ScriptMainData
    }
  }
  ${SCRIPT_MAIN_DATA}
`;

export type GetScriptsData = {
  scripts: ScriptMainData[];
};

export type ScriptWithParametersAndRevisions = ScriptWithParameters & {
  revisions?: ScriptRevisionFullData[];
  revision?: ScriptRevisionFullData;
  latest: ScriptRevisionFullData;
  preloadedScript?: PreloadedScript;
};

export type GetScriptData = {
  scripts: ScriptListData[];
};

export type GetPublicScriptData = {
  publicScripts: ScriptListData[];
};

export type GetStrategyManagerScriptsData = {
  scripts?: ScriptListDetails[];
  publicScripts?: ScriptListDetails[];
};

export const GET_SCRIPTS_LIST_DATA = gql`
  query GetScriptsListData(
    $query: PageQuery!
    $sort: ScriptSort
    $filters: [ScriptFilter]
    $language: ScriptLanguage
  ) {
    scripts(
      query: $query
      sort: $sort
      filters: $filters
      language: $language
    ) {
      ...ScriptListData
      preloadedScript
      latest {
        ...ScriptRevisionDateData
      }
    }
  }
  ${SCRIPT_LIST_DATA}
  ${SCRIPTREVISION_DATE_DATA}
`;

export type GetScriptListCountData = {
  scriptsCount?: number;
  publicScriptsCount?: number;
};

export const GET_SCRIPTS_LIST_COUNT = gql`
  query GetScriptsListCount(
    $filters: [ScriptFilter]
    $language: ScriptLanguage
  ) {
    scriptsCount(filters: $filters, language: $language)
  }
`;

export const GET_PUBLIC_SCRIPTS_LIST_DATA = gql`
  query GetPublicScriptsListData(
    $query: PageQuery!
    $sort: ScriptSort
    $filters: String
    $language: ScriptLanguage
  ) {
    publicScripts(
      query: $query
      sort: $sort
      filters: $filters
      language: $language
    ) {
      ...ScriptListData
      latest {
        ...ScriptRevisionDateData
      }
      ownerInfo {
        author
        nickname
        isTunedTeam
      }
    }
  }
  ${SCRIPT_LIST_DATA}
  ${SCRIPTREVISION_DATE_DATA}
`;

export const GET_PUBLIC_SCRIPTS_DATA = gql`
  query GetPublicScriptsData(
    $query: PageQuery!
    $sort: ScriptSort
    $filters: String
    $language: ScriptLanguage
  ) {
    publicScripts(
      query: $query
      sort: $sort
      filters: $filters
      language: $language
    ) {
      id
      name
      language
      timesCopied
      ownerInfo {
        author
        nickname
      }
      latest {
        updatedAt
      }
    }
  }
`;

export const GET_PUBLIC_SCRIPTS_LIST_COUNT = gql`
  query GetPublicScriptsListCount($filters: String, $language: ScriptLanguage) {
    publicScriptsCount(filters: $filters, language: $language)
  }
`;

export const GET_CREDENTIALS = gql`
  query GetCredentials {
    credentials {
      ...CredentialData
    }
  }
  ${CREDENTIAL_DATA}
`;

export type GetCredentialsData = {
  credentials: Credential[];
};

export const GET_CREDENTIALS_WITH_FUNDS = gql`
  query GetCredentialsWithFunds {
    credentials {
      ...CredentialData
      assets {
        free {
          ...AssetData
          usdValue
          btcValue
        }
        total {
          ...AssetData
          usdValue
          btcValue
        }
        allocated {
          ...AssetData
          usdValue
          btcValue
        }
        updatedAt
      }
    }
  }
  ${CREDENTIAL_DATA}
  ${ASSET_DATA}
`;

export const GET_SCRIPTS_AND_CREDENTIALS = gql`
  query GetScriptsAndCredentials($exchange: Exchange!) {
    credentials(exchange: $exchange) {
      ...CredentialData
    }
    scripts {
      ...ScriptMainData
      latest {
        ...ScriptRevisionData
      }
    }
  }
  ${CREDENTIAL_DATA}
  ${SCRIPT_MAIN_DATA}
  ${SCRIPT_REVISION_DATA}
`;

export type GetScriptDetailsWithRevisionData = {
  script?: ScriptWithParametersAndRevisions;
};

export type GetScriptDetailsWithRevisionVars = {
  scriptId: ID;
  revisionId: ID;
  useLatest?: Boolean;
  isPublic?: boolean;
};

export const GET_SCRIPT_DETAILS_WITH_REVISION = gql`
  query GetScriptDetailsWithRevision(
    $scriptId: ID!
    $isPublic: Boolean
    $revisionId: ID!
    $useLatest: Boolean!
  ) {
    script(id: $scriptId, isPublic: $isPublic) {
      ...ScriptMainData
      isUsed
      latest {
        ...ScriptRevisionFullData
      }
      preview {
        id
      }
      revision(id: $revisionId) @skip(if: $useLatest) {
        ...ScriptRevisionFullData
      }
      preloadedScript
    }
  }
  ${SCRIPT_MAIN_DATA}
  ${SCRIPTREVISION_FULL_DATA}
`;

export const GET_SCRIPT_DETAILS = gql`
  query GetScriptDetails($scriptId: ID!, $isPublic: Boolean) {
    script(id: $scriptId, isPublic: $isPublic) {
      ...ScriptMainData
      isUsed
      latest {
        ...ScriptRevisionFullData
      }
      preview {
        id
      }
      revisions {
        ...ScriptRevisionFullData
      }
      preloadedScript
    }
  }
  ${SCRIPT_MAIN_DATA}
  ${SCRIPTREVISION_FULL_DATA}
`;

export const GET_PUBLIC_SCRIPT_DETAILS = gql`
  query GetPublicScriptDetails($scriptId: ID!, $isPublic: Boolean) {
    script(id: $scriptId, isPublic: $isPublic) {
      ...ScriptMainData
      isUsed
      latest {
        ...ScriptRevisionFullData
      }
      preview {
        id
      }
      revisions {
        ...ScriptRevisionFullData
      }
    }
  }
  ${SCRIPT_MAIN_DATA}
  ${SCRIPTREVISION_FULL_DATA}
`;

export type GetScriptNotesData = {
  script: Pick<Script, "id" | "notes">;
};

export const GET_SCRIPT_NOTES = gql`
  query GetScriptNotes($scriptId: ID!, $isPublic: Boolean) {
    script(id: $scriptId, isPublic: $isPublic) {
      id
      notes
    }
  }
`;

export type GetScriptRevisionsData = {
  script: Pick<Script, "revisions">;
};

export type GetScriptRevisionsVars = {
  scriptId: string;
};

export const GET_SCRIPT_REVISIONS = gql`
  query GetScriptRevisions($scriptId: ID!) {
    script(id: $scriptId) {
      revisions {
        ...ScriptRevisionFullData
      }
    }
  }
  ${SCRIPTREVISION_FULL_DATA}
`;

export const GET_SCRIPT_REVISIONS_DATES = gql`
  query GetScriptRevisionsDates($scriptId: ID!) {
    script(id: $scriptId) {
      revisions {
        ...ScriptRevisionDateData
      }
    }
  }
  ${SCRIPTREVISION_DATE_DATA}
`;

export type GetScriptDetailsData = {
  script?: ScriptMainData & {
    latest: ScriptRevisionFullData;
    preview?: { id: ID };
    revisions: ScriptRevisionFullData[];
    preloadedScript?: PreloadedScript;
  };
};

export const GET_LATEST_SCRIPT_REVISION_ID = gql`
  query GetLatestScriptRevisionId($scriptId: ID!) {
    script(id: $scriptId) {
      id
      latest {
        id
        status
      }
    }
  }
`;

export const GET_LATEST_PUBLIC_SCRIPT_REVISION_ID = gql`
  query GetLatestPublicScriptRevisionId($scriptId: ID!, $isPublic: Boolean) {
    script(id: $scriptId, isPublic: $isPublic) {
      id
      latest {
        id
        status
      }
    }
  }
`;

export type GetLatestScriptRevisionIdData = {
  script?: Pick<Script, "id"> & {
    latest: Pick<ScriptRevision, "id" | "status">;
  };
};

export type GetLatestScriptRevisionIdVars = {
  scriptId: string;
  isPublic?: boolean;
};

// SCRIPT REVISIONS

export const GET_SCRIPT_REVISION_DETAILS = gql`
  query GetScriptRevisionDetails(
    $scriptId: ID!
    $scriptRevisionId: ID!
    $isPublic: Boolean
  ) {
    script(id: $scriptId, isPublic: $isPublic) {
      id
      language
      revision(id: $scriptRevisionId) {
        ...ScriptRevisionData
      }
    }
  }
  ${SCRIPT_REVISION_DATA}
`;

export type GetScriptRevisionDetailsData = {
  script: {
    id: string;
    language: ScriptLanguage;
    revision: Pick<
      ScriptRevision,
      | ScriptRevisionDataFields
      | "plotDefinition"
      | "errors"
      | "warnings"
      | "infos"
    >;
  };
};

export type GetScriptRevisionDetailsVars = {
  scriptId: string;
  scriptRevisionId: string;
  isPublic?: boolean;
};

// EXECUTIONS

export const VALIDATE_EXECUTION = gql`
  query ValidateExecution($executionId: ID!) {
    execution(id: $executionId) {
      id
      type
    }
  }
`;

export const EXECUTION_PREVIEW_SUBSCRIPTION = gql`
  subscription ExecutionPreviewSubscription($executionIds: [ID]!) {
    execution(ids: $executionIds) {
      ...ExecutionPreviewData
    }
  }
  ${EXECUTION_PREVIEW_DATA}
`;

export const EXECUTION_SUBSCRIPTION = gql`
  subscription ExecutionSubscription($executionIds: [ID]!) {
    execution(ids: $executionIds) {
      ...ExecutionSubscriptionData
    }
  }
  ${EXECUTION_SUBSCRIPTION_DATA}
`;

export const GET_EXECUTION_CONTROLS = gql`
  query GetExecutionControls($id: ID!) {
    execution(id: $id) {
      ...ExecutionControlData
      scriptDetails {
        id
        revisionId
        name
        version
      }
      syndication {
        id
        name
      }
    }
  }
  ${EXECUTION_CONTROL_DATA}
`;

export const GET_BOT_CONTROLS = gql`
  query GetBotControls($id: ID!) {
    publicSyndication(id: $id) {
      id
      name
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      candleSize
      multiCoinCurrency
      exchange
      startedAt
    }
  }
`;

// For individual rows in a list to update themselves
export const GET_EXECUTION_ROW = gql`
  query GetExecutionRow($executionId: ID!) {
    execution(id: $executionId) {
      ...ExecutionListData
    }
  }
  ${EXECUTION_LIST_DATA}
`;

export const GET_SHARED_EXECUTION_CONTROLS = gql`
  query GetSharedExecutionControls($shareToken: ID!) {
    sharedExecution(shareToken: $shareToken) {
      shareToken
      name
      type
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      multiCoinCurrency
      exchange
      candleSize
      rangeStart
      rangeEnd
      startedAt
      endedAt
      scriptName
    }
  }
`;

export type GetExecutionControlsData = {
  execution: Pick<Execution, ExecutionControlDataFields | "scriptDetails">;
};

export type GetExecutionControlsVars = {
  executionId: string;
};

export type GetExecutionData = {
  execution?: Execution;
};

export const GET_EXECUTION_DETAILS_FOR_COPY = gql`
  query GetExecutionDetailsForCopy($executionId: ID!) {
    execution(id: $executionId) {
      ...ExecutionDetailsData
      isPack
      autoRebalance
      preloadedScript
      credential {
        id
        assets {
          free {
            ...AssetData
          }
        }
      }
    }
  }
  ${EXECUTION_DETAILS_DATA}
  ${ASSET_DATA}
`;

export const GET_EXECUTION_DETAILS = gql`
  query GetExecutionDetails($executionId: ID!) {
    execution(id: $executionId) {
      ...ExecutionDetailsData
    }
  }
  ${EXECUTION_DETAILS_DATA}
`;

export type GetExecutionDetailsData = {
  execution?: Pick<Execution, ExecutionDetailsDataFields>;
};

export type GetMultivariantDetailsData = {
  multivariant: Multivariant;
};

export const GET_EXECUTION_TRADES = gql`
  query GetExecutionTrades(
    $executionId: ID!
    $num: Int!
    $sortDirection: SortDirection!
    $from: ID
  ) {
    execution(id: $executionId) {
      id
      exchange
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      measurements {
        ...TradesMeasurementData
      }
      trades(
        query: { numberOfResults: $num, fromId: $from }
        sortDirection: $sortDirection
      ) {
        ...TradeData
      }
      syndicationId
    }
  }
  ${TRADE_DATA}
  ${TRADES_MEASUREMENT_DATA}
`;

export const GET_EXECUTION_MEASUREMENTS = gql`
  query GetExecutionMeasurements($executionId: ID!) {
    execution(id: $executionId) {
      favorite
      id
      measurements {
        ...TradesMeasurementData
      }
    }
  }
  ${TRADES_MEASUREMENT_DATA}
`;

export const GET_EXECUTION_LOG = gql`
  query GetExecutionLog(
    $executionId: ID!
    $num: Int!
    $sortDirection: SortDirection!
    $from: ID
  ) {
    execution(id: $executionId) {
      id
      logs(
        query: { numberOfResults: $num, fromId: $from }
        sortDirection: $sortDirection
      ) {
        ...ExecutionLogData
      }
    }
  }
  ${EXECUTION_LOG_DATA}
`;

export type GetSharedExecutionData = {
  sharedExecution?: SharedExecution;
};

export type GetSharedExecutionVars = {
  shareToken?: ID;
  num?: number;
  sortDirection?: SortDirection;
  from?: ID;
};

export const GET_SHARED_EXECUTION_TRADES = gql`
  query GetSharedExecutionTrades(
    $shareToken: ID!
    $num: Int!
    $sortDirection: SortDirection!
    $from: ID
  ) {
    sharedExecution(shareToken: $shareToken) {
      shareToken
      exchange
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      multiCoinCurrency
      measurements {
        ...TradesMeasurementData
      }
      trades(
        query: { numberOfResults: $num, fromId: $from }
        sortDirection: $sortDirection
      ) {
        ...TradeData
      }
    }
  }
  ${TRADE_DATA}
  ${TRADES_MEASUREMENT_DATA}
`;

export const TRADES_SUBSCRIPTION = gql`
  subscription TradesSubscription(
    $executionId: ID!
    $sortDirection: SortDirection!
  ) {
    trade(executionId: $executionId, sortDirection: $sortDirection) {
      ...TradeData
    }
  }
  ${TRADE_DATA}
`;

export type GetExecutionTradesVars = {
  executionId: string;
  num: number;
  sortDirection: string;
  from?: string;
};

export type GetExecutionsCountData = {
  executionsCount?: CountData;
  strategiesCount?: CountData;
  syndicationsCount?: CountData;
  execution?: Pick<Execution, "id"> & {
    subscriptionsCount: CountData;
  };
};

// STRATEGIES

export type GetExecutionListData = {
  strategies?: Execution[];
  executions?: Execution[];
  syndications?: Execution[];
  execution?: Execution;
  multivariant?: Multivariant;
};

export type GetStrategiesIdsData = {
  strategies?: Pick<
    Execution,
    | "id"
    | "type"
    | "exchange"
    | "currencyPair"
    | "currencyPairDetails"
    | "isPack"
    | "scriptDetails"
  >[];
};

export type GetStrategiesIdsVars = {
  query?: PageQuery;
  sort?: ExecutionSort;
  filters?: ExecutionFilter[];
};

export const GET_STRATEGIES_IDS = gql`
  query GetStrategies(
    $query: PageQuery!
    $sort: ExecutionSort
    $filters: [ExecutionFilter]
  ) {
    strategies(query: $query, sort: $sort, filters: $filters) {
      id
      isPack
      type
      status
      exchange
      leverage
      leverageShort
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      syndicationId
      scriptDetails {
        name
      }
    }
  }
`;

// Duplicate of above query to investigate callsite
// with increased error rate. These should eventually
// be colocated in each callsite and renamed.
export const GET_STRATEGIES_INVEST = gql`
  query GetStrategiesInvestPage(
    $query: PageQuery!
    $sort: ExecutionSort
    $filters: [ExecutionFilter]
  ) {
    strategies(query: $query, sort: $sort, filters: $filters) {
      id
      isPack
      type
      status
      exchange
      leverage
      leverageShort
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      syndicationId
      scriptDetails {
        name
      }
    }
  }
`;

export const GET_STRATEGIES_COUNT = gql`
  query GetStrategiesCount($filters: [ExecutionFilter]) {
    strategiesCount(filters: $filters) {
      count
    }
  }
`;

export const GET_SHARED_EXECUTION = gql`
  query GetSharedExecution($shareToken: ID!) {
    sharedExecution(shareToken: $shareToken) {
      ...SharedExecutionData
      scriptName
    }
  }
  ${SHARED_EXECUTION_DATA}
`;

// SIMULATIONS

export const GET_SIMULATIONS_IDS = gql`
  query GetSimulations(
    $query: PageQuery!
    $sort: ExecutionSort
    $filters: [ExecutionFilter]
  ) {
    simulations(query: $query, sort: $sort, filters: $filters) {
      id
      isPack
      type
      exchange
      currencyPair
    }
  }
`;

export const GET_SIMULATIONS_COUNT = gql`
  query GetSimulationsCount($filters: [ExecutionFilter]) {
    simulationsCount(filters: $filters) {
      count
    }
  }
`;

// MULTIVARIANTS
export const GET_MULTIVARIANTS = gql`
  query GetMultivariants(
    $query: PageQuery!
    $sort: MultivariantSort
    $filters: [MultivariantFilter]
  ) {
    multivariants(query: $query, sort: $sort, filters: $filters) {
      ...MultivariantListData
    }
  }
  ${MULTIVARIANT_LIST_DATA}
`;

export type GetMultivariantsCountData = {
  multivariantsCount?: CountData;
};

export const GET_MULTIVARIANTS_COUNT = gql`
  query GetMultivariantsCount($filters: [MultivariantFilter]) {
    multivariantsCount(filters: $filters) {
      count
    }
  }
`;

export const MULTIVARIANTS_PER_PAGE = 20;

export const MULTIVARIANT_SUBSCRIPTION = gql`
  subscription MultivariantSubscription($multivariantIds: [ID]!) {
    multivariant(ids: $multivariantIds) {
      ...MultivariantListData
    }
  }
  ${MULTIVARIANT_LIST_DATA}
`;

export const SCRIPT_REVISION_SUBSCRIPTION = gql`
  subscription ScriptRevisionSubscription($id: ID!) {
    scriptRevision(scriptRevisionId: $id) {
      ...ScriptRevisionFullData
    }
  }
  ${SCRIPTREVISION_FULL_DATA}
`;

export const CANDLE_SUBSCRIPTION = gql`
  subscription CandleSubscription(
    $exchange: Exchange!
    $pair: CurrencyPair!
    $candleSize: Resolution!
  ) {
    candle(exchange: $exchange, pair: $pair, candleSize: $candleSize) {
      open
      high
      low
      volume
      close
      startTime
      endTime
    }
  }
`;

export const EXECUTION_ORDER_SUBSCRIPTION = gql`
  subscription ExecutionOrderSubscription($executionId: ID!) {
    order(executionId: $executionId) {
      placedTime
      side
      placedAmount
    }
  }
`;

export const EXECUTION_ORDER_LIST_SUBSCRIPTION = gql`
  subscription ExecutionOrderListSubscription($executionId: ID!) {
    order(executionId: $executionId) {
      ...OrderMainData
      ...OrderPreCommissionData
      ...OrderPostCommissionData
    }
  }
  ${ORDER_MAIN_DATA}
  ${ORDER_PRE_COMMISSION_DATA}
  ${ORDER_POST_COMMISSION_DATA}
`;

export const EXECUTION_PLOT_SUBSCRIPTION = gql`
  subscription ExecutionPlotSubscription($executionId: ID!) {
    plot(executionId: $executionId) {
      plotId
      time
      value
    }
  }
`;

export const EXECUTION_COLOR_SUBSCRIPTION = gql`
  subscription ExecutionColorSubscription($executionId: ID!) {
    color(executionId: $executionId) {
      plotId
      time
      value
    }
  }
`;

// Using 2 fragments so Apollo can manage its cache
export const GET_MULTIVARIANT_DETAILS = gql`
  query GetMultivariantDetails(
    $multivariantId: ID!
    $filters: [ExecutionFilter]
  ) {
    multivariant(id: $multivariantId) {
      ...MultivariantListData
      ...MultivariantDetailsData
      executionsCount(filters: $filters) {
        count
      }
    }
  }
  ${MULTIVARIANT_LIST_DATA}
  ${MULTIVARIANT_DETAILS_DATA}
`;

export type GetMultivariantComposerData = {
  multivariant: Pick<Multivariant, "id" | "multipleValueFields">;
};

export type GetMultivariantComposerVars = {
  multivariantId: string;
};

export const GET_MULTIVARIANT_COMPOSER_DATA = gql`
  query GetMultivariantComposerData($multivariantId: ID!) {
    multivariant(id: $multivariantId) {
      ...MultivariantComposerData
    }
  }
  ${MULTIVARIANT_COMPOSER_DATA}
`;

export const GET_MULTIVARIANT_EXECUTIONS = gql`
  query GetMultivariantExecutions(
    $multivariantId: ID!
    $query: PageQuery!
    $sort: ExecutionSort
    $filters: [ExecutionFilter]
  ) {
    multivariant(id: $multivariantId) {
      ...MultivariantExecutionsData
    }
  }
  ${MULTIVARIANT_EXECUTIONS_DATA}
`;

export type GetMultivariantData = {
  multivariants: PartialMultivariant[];
};

export const CREATE_STRATEGY_FORM = gql`
  query CreateStrategyForm {
    credentials {
      ...CredentialData
      assets(syncWithExchange: true) {
        free {
          ...AssetData
        }
      }
    }
    scripts {
      ...ScriptMainData
      latestValid {
        ...ScriptRevisionData
      }
    }
  }
  ${CREDENTIAL_DATA}
  ${ASSET_DATA}
  ${SCRIPT_MAIN_DATA}
  ${SCRIPT_REVISION_DATA}
`;

export const CREATE_SIMULATION_FORM = gql`
  query CreateSimulationForm {
    scripts {
      ...ScriptMainData
      latestValid {
        ...ScriptRevisionData
      }
    }
  }
  ${SCRIPT_MAIN_DATA}
  ${SCRIPT_REVISION_DATA}
`;

export type GetFilterPresetsData = {
  filterPresets: FilterPreset[];
};

export type GetMyTagsData = {
  tags: Tag[];
};

export const GET_MY_TAGS = gql`
  query getMyTags {
    tags {
      id
      name
      color
    }
  }
`;

export type GetExecutionInfoForPacksData = {
  execution: Pick<
    Execution,
    | "id"
    | "name"
    | "exchange"
    | "currencyPair"
    | "currencyPairDetails"
    | "multiCoinCurrency"
    | "scriptDetails"
    | "measurements"
    | "startedAt"
    | "endedAt"
    | "rangeStart"
    | "rangeEnd"
    | "runNumber"
  >;
};

export type GetExecutionInfoForPacksVars = {
  executionId: string;
};

export const GET_EXECUTION_INFO_FOR_PACKS = gql`
  query GetExecutionInfoForPacks($executionId: ID!) {
    execution(id: $executionId) {
      id
      name
      exchange
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      multiCoinCurrency
      scriptDetails {
        id
        name
        version
      }
      measurements {
        profitability
        maxDrawdown
        avgMonthlyProfit
        avgWinMonth
        avgLoseMonth
      }
      startedAt
      endedAt
      rangeStart
      rangeEnd
      runNumber
    }
  }
`;

export type GetPackExecutionsData = {
  packExecutions: PackExecutionDataFields[];
};

export type GetPackExecutionsVars = {
  packId: ID;
  query: PageQuery;
  sort?: ExecutionSort;
  filters?: ExecutionFilter[];
};

export const GET_PACK_EXECUTIONS = gql`
  query GetPackExecutions(
    $packId: ID!
    $query: PageQuery!
    $sort: ExecutionSort
    $filters: [ExecutionFilter]
  ) {
    packExecutions(
      packId: $packId
      query: $query
      sort: $sort
      filters: $filters
    ) {
      id
      type
      exchange
      currencyPair
      packPercentage
    }
  }
`;

export const GET_EXECUTIONS = gql`
  query GetExecutions(
    $query: PageQuery!
    $sort: ExecutionSort
    $filters: [ExecutionFilter]
    $stage: ExecutionStage
  ) {
    executions(query: $query, sort: $sort, filters: $filters, stage: $stage) {
      id
      isPack
      type
      status
      exchange
      leverage
      leverageShort
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      syndicationId
    }
  }
`;

export const GET_EXECUTIONS_COUNT = gql`
  query GetExecutionsCount(
    $filters: [ExecutionFilter]
    $stage: ExecutionStage
  ) {
    executionsCount(filters: $filters, stage: $stage) {
      count
    }
  }
`;

export const GET_SYNDICATIONS = gql`
  query GetSyndications(
    $query: PageQuery!
    $sort: ExecutionSort
    $filters: [ExecutionFilter]
  ) {
    syndications(query: $query, sort: $sort, filters: $filters) {
      id
      isPack
      type
      exchange
      currencyPair
    }
  }
`;

export const GET_SYNDICATIONS_COUNT = gql`
  query GetSyndicationsCount($filters: [ExecutionFilter]) {
    syndicationsCount(filters: $filters) {
      count
    }
  }
`;

export type GetGettingStartedData = {
  gettingStarted: GettingStarted;
};

export const GET_GETTING_STARTED = gql`
  query GetGettingStarted {
    gettingStarted {
      openedEditor
      createdExperiment
      createdMultivariant
      promotedExperiment
      promotedCandidate
      addedCredential
      launchedLive
    }
  }
`;

export const GET_PUBLIC_SYNDICATION = gql`
  query GetPublicSyndication($id: ID!) {
    publicSyndication(id: $id) {
      ...PublicSyndicationData
    }
  }
  ${PUBLIC_SYNDICATION_DATA}
`;

export const PRESIGNED_UPLOAD_URL = gql`
  query PresignedUploadUrl($contentType: String, $contentLength: String) {
    presignedUploadUrl(
      contentType: $contentType
      contentLength: $contentLength
    ) {
      url
      key
    }
  }
`;

export type GetPublicSyndicationData = {
  publicSyndication?: PublicSyndication;
};

export const GET_PUBLIC_SYNDICATION_DETAILS = gql`
  query GetPublicSyndicationDetails($id: ID!) {
    publicSyndication(id: $id) {
      ...PublicSyndicationDetailsData
    }
  }
  ${PUBLIC_SYNDICATION_DETAILS_DATA}
`;

export type GetSyndicationAnalysisData = {
  publicSyndication?: Pick<
    PublicSyndication,
    "id" | "analysis" | "analysisWithRange" | "performance"
  >;
  privateSyndication?: Pick<
    PublicSyndication,
    "id" | "analysis" | "analysisWithRange" | "performance"
  >;
};

export type GetSyndicationAnalysisVars = {
  shareToken?: ID;
  id?: ID;
  rangeDays?: number;
  hasRange?: boolean;
};

export const GET_PUBLIC_SYNDICATION_ANALYSIS = gql`
  query GetPublicSyndicationAnalysis(
    $id: ID!
    $rangeDays: Int
    $hasRange: Boolean!
  ) {
    publicSyndication(id: $id) {
      id
      analysis @skip(if: $hasRange) {
        ...AnalysisData
      }
      analysisWithRange(rangeDays: $rangeDays) @include(if: $hasRange) {
        ...AnalysisData
      }
    }
  }
  ${ANALYSIS}
`;

export type GetPublicSyndicationMetricsData = {
  publicSyndication?: Pick<
    PublicSyndication,
    "id" | "performance" | "analysisWithRange"
  >;
};

export type GetPublicSyndicationMetricsVars = {
  id?: ID;
  rangeDays?: number;
};

export const GET_PUBLIC_SYNDICATION_METRICS = gql`
  query GetPublicSyndicationMetrics($id: ID!, $rangeDays: Int) {
    publicSyndication(id: $id) {
      id
      performance(rangeDays: $rangeDays)
      analysisWithRange(rangeDays: 30) {
        ...AnalysisData
      }
    }
  }
  ${ANALYSIS}
`;

export type GetPublicSyndicationUpdatesData = {
  publicSyndication: {
    id: string;
    updates?: SyndicationUpdatesConnection;
    pinnedUpdate?: SyndicationUpdate;
  };
};

export const GET_PUBLIC_SYNDICATION_UPDATES = gql`
  query GetPublicSyndicationUpdates($id: ID!, $first: Int, $after: String) {
    publicSyndication(id: $id) {
      id
      pinnedUpdate {
        ...SyndicationUpdate
      }
      updates(first: $first, after: $after) {
        edges {
          cursor
          node {
            ...SyndicationUpdate
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
  ${SYNDICATION_UPDATE}
`;

export type GetPrivateSyndicationData = {
  privateSyndication?: PublicSyndication;
};

export const GET_PRIVATE_SYNDICATION = gql`
  query GetPrivateSyndication($shareToken: ID!) {
    privateSyndication(shareToken: $shareToken) {
      ...PublicSyndicationDetailsData
      owner {
        id
        firstName
        lastName
        tradingSince
        companyName
        nickname
        country
        description
        twitterUrl
        discordUrl
        telegramUrl
        mediumUrl
        linkedinUrl
        personalBlogUrl
        personalWebsiteUrl
        exchanges
        profilePhotoUrl
      }
    }
  }
  ${PUBLIC_SYNDICATION_DETAILS_DATA}
`;

export const GET_PRIVATE_SYNDICATION_ANALYSIS = gql`
  query GetPrivateSyndicationAnalysis(
    $shareToken: ID!
    $rangeDays: Int
    $hasRange: Boolean!
  ) {
    privateSyndication(shareToken: $shareToken) {
      id
      analysis @skip(if: $hasRange) {
        ...AnalysisData
      }
      analysisWithRange(rangeDays: $rangeDays) @include(if: $hasRange) {
        ...AnalysisData
      }
    }
  }
  ${ANALYSIS}
`;

export const GET_PUBLIC_USER = gql`
  query GetPublicUser($nickname: String!, $filters: [ExecutionFilter]) {
    publicUser(nickname: $nickname) {
      id
      firstName
      lastName
      tradingSince
      companyName
      nickname
      country
      description
      twitterUrl
      discordUrl
      telegramUrl
      mediumUrl
      linkedinUrl
      personalBlogUrl
      personalWebsiteUrl
      exchanges
      syndicationsCount(filters: $filters) {
        count
      }
      profilePhotoUrl
      scriptsCount
    }
  }
`;

export type GetPublicUserSyndicationListData = {
  publicProfile: Pick<PublicUser, "id" | "syndications" | "syndicationsCount">;
};

export const GET_PUBLIC_USER_SYNDICATION_LIST = gql`
  query GetPublicUserSyndicationList(
    $userId: String!
    $num: Int!
    $from: ID
    $filters: [ExecutionFilter]
    $sort: ExecutionSort
  ) {
    publicProfile(userId: $userId) {
      id
      syndications(
        query: { numberOfResults: $num, fromId: $from }
        filters: $filters
        sort: $sort
      ) {
        ...PublicSyndicationListData
        analysis {
          realizedProfitPerc
        }
      }
      syndicationsCount(filters: $filters) {
        count
      }
    }
  }
  ${PUBLIC_SYNDICATION_LIST_DATA}
`;

export type GetPublicUserScriptListData = {
  publicProfile: Pick<PublicUser, "id" | "scripts" | "scriptsCount">;
};

export const GET_PUBLIC_USER_SCRIPT_LIST = gql`
  query GetPublicUserScriptList($userId: String!, $num: Int!, $from: ID) {
    publicProfile(userId: $userId) {
      id
      scripts(query: { numberOfResults: $num, fromId: $from }) {
        id
        name
        language
        favorite
        notes
        isPublic
        latestVersionNumber
        timesCopied
        latest {
          ...ScriptRevisionDateData
        }
      }
      scriptsCount
    }
  }
  ${SCRIPTREVISION_DATE_DATA}
`;

export type GetPublicSyndicationsListData = {
  syndications: PublicSyndicationListData[];
};

export type GetPublicProfileVars = {
  nickname: string;
  filters?: ExecutionFilter[];
};

export type GetPublicProfileSyndicationVars = {
  userId: ID;
  num: number;
  filters?: ExecutionFilter[];
  sort?: ExecutionSort;
  from?: string;
};

export type GetPublicProfileScriptVars = {
  userId: ID;
  num: number;
  from?: string;
};

export type GetPublicSyndicationsData = {
  publicSyndications?: Pick<PublicSyndication, "id" | "name" | "owner">[];
};

export type GetPublicSyndicationsVars = {
  query?: PageQuery;
  filters?: ExecutionFilter[];
};

export const GET_PUBLIC_SYNDICATIONS = gql`
  query GetPublicSyndications(
    $query: PageQuery!
    $sort: ExecutionSort
    $filters: [ExecutionFilter]
    $exchanges: [Exchange]
  ) {
    publicSyndications(
      query: $query
      sort: $sort
      filters: $filters
      exchanges: $exchanges
    ) {
      id
      name
      owner {
        nickname
      }
    }
  }
`;

export const GET_PUBLIC_SYNDICATIONS_LIST = gql`
  query GetPublicSyndicationsList(
    $query: PageQuery!
    $sort: ExecutionSort
    $filters: [ExecutionFilter]
    $exchanges: [Exchange]
    $currencyPairs: [CurrencyPair]
    $fieldComparisons: [ExecutionFieldComparison]
  ) {
    publicSyndications(
      query: $query
      sort: $sort
      filters: $filters
      exchanges: $exchanges
      currencyPairs: $currencyPairs
      fieldComparisons: $fieldComparisons
    ) {
      ...PublicSyndicationListData
      owner {
        firstName
        lastName
        companyName
        nickname
      }
    }
  }
  ${PUBLIC_SYNDICATION_LIST_DATA}
`;

export const GET_PUBLIC_SYNDICATIONS_COUNT = gql`
  query GetPublicSyndicationsCount(
    $filters: [ExecutionFilter]
    $exchanges: [Exchange]
    $currencyPairs: [CurrencyPair]
    $fieldComparisons: [ExecutionFieldComparison]
  ) {
    publicSyndicationsCount(
      filters: $filters
      exchanges: $exchanges
      currencyPairs: $currencyPairs
      fieldComparisons: $fieldComparisons
    ) {
      count
    }
  }
`;

export type GetPublicProfilesData = {
  publicProfiles?: Pick<PublicUser, "id" | "nickname">[];
};

export type GetPublicProfilesVars = {
  query?: PageQuery;
  filters?: UserFilter[];
};

export const GET_PUBLIC_PROFILES = gql`
  query GetPublicUsers($query: PageQuery!, $filters: [UserFilter]) {
    publicProfiles(query: $query, filters: $filters) {
      id
      nickname
    }
  }
`;

export type GetAdvancedSearchData = {
  publicProfiles?: Pick<PublicUser, "id" | "nickname">[];
  mostPopularCreators?: MostPopularCreatorsConnection;
  publicSyndications?: PublicSyndication[];
  mostPopularSyndications?: MostPopularSyndicationsConnection;
  publicScripts?: Script[];
};

export type GetAdvancedSearchVars = {
  first?: number;
  query?: PageQuery;
  rangeDays?: number;
  filters?: { filterKey: string; filterType: string; value: string }[] | string;
  executionFilters?: ExecutionFilter[];
  languge?: string;
  sort?: {
    sortDirection: string;
    sortKey: string;
  };
  fieldComparison?: ExecutionFieldComparison[];
  exchanges?: Exchange[];
};

export const GET_PUBLIC_USERS_LIST = gql`
  query GetPublicUsersData(
    $query: PageQuery!
    $filters: [UserFilter]
    $executionFilters: [ExecutionFilter]
    $exchanges: [Exchange]
  ) {
    publicProfiles(
      query: $query
      filters: $filters
      executionFilters: $executionFilters
      exchanges: $exchanges
    ) {
      id
      nickname
    }
  }
`;

export type GetAdvancedSearchCountData = {
  publicProfilesCount?: number;
  publicSyndicationsCount?: CountData;
  publicScriptsCount?: number;
};

export type GetAdvancedSearchCountVars = {
  first?: number;
  query?: PageQuery;
  rangeDays?: number;
  filters?: { filterKey: string; filterType: string; value: string }[] | string;
  executionFilters?: ExecutionFilter[];
  languge?: string;
  sort?: {
    sortDirection: string;
    sortKey: string;
  };
  fieldComparison?: ExecutionFieldComparison[];
};

export const GET_PUBLIC_USERS_COUNT = gql`
  query GetPublicUsersCount(
    $filters: [UserFilter]
    $executionFilters: [ExecutionFilter]
    $exchanges: [Exchange]
  ) {
    publicProfilesCount(
      filters: $filters
      executionFilters: $executionFilters
      exchanges: $exchanges
    )
  }
`;

export type GetPublicUserData = {
  publicUser?: PublicUser;
};

export type GetPublicUserVars = {
  nickname?: string;
};

export const GET_PUBLIC_USER_DETAILS = gql`
  query GetPublicUserDetails($nickname: String!) {
    publicUser(nickname: $nickname) {
      id
      firstName
      lastName
      tradingSince
      companyName
      nickname
      description
      exchanges
      profilePhotoUrl
    }
  }
`;

export type GetPublicUserSyndicationDetailsData = {
  publicUser?: Pick<
    PublicUser,
    | "id"
    | "totalAllocated"
    | "profitableSyndicationsCount"
    | "unprofitableSyndicationsCount"
    | "syndicationsCount"
    | "totalSyndicationsTrades"
  >;
};

export type GetPublicUserSyndicationDetailsVars = {
  nickname?: string;
  filters?: ExecutionFilter[];
};

export const GET_PUBLIC_USER_SYNDICATION_DETAILS = gql`
  query GetPublicUserSyndicationDetails(
    $nickname: String!
    $filters: [ExecutionFilter]
  ) {
    publicUser(nickname: $nickname) {
      id
      totalAllocated
      profitableSyndicationsCount
      unprofitableSyndicationsCount
      syndicationsCount(filters: $filters) {
        count
      }
      totalSyndicationsTrades
    }
  }
`;

export const GET_SYNDICATION_SUBSCRIPTIONS = gql`
  query GetSyndicationSubscriptions(
    $syndicationId: ID!
    $query: PageQuery!
    $sort: ExecutionSort
    $filters: [ExecutionFilter]
  ) {
    execution(id: $syndicationId) {
      id
      name
      subscriptions(query: $query, sort: $sort, filters: $filters) {
        id
        shareToken
      }
    }
  }
`;

export const GET_SYNDICATION_SUBSCRIPTIONS_COUNT = gql`
  query GetSyndicationSubscriptionsCount(
    $syndicationId: ID!
    $filters: [ExecutionFilter]
  ) {
    execution(id: $syndicationId) {
      id
      subscriptionsCount(filters: $filters) {
        count
      }
    }
  }
`;

export const GET_SUBSCRIPTION = gql`
  query GetSubscription($executionId: ID!, $syndicationId: ID!) {
    execution(id: $syndicationId) {
      id
      subscription(id: $executionId) {
        id
        ownerNickname
        rangeStart
        rangeEnd
        startedAt
        endedAt
        allocation
        type
        subscriptionStatus
        ...SharedExecutionData
      }
    }
  }
  ${SHARED_EXECUTION_DATA}
`;

export const BACKTEST_PROGRESS = gql`
  subscription BacktestProgress($id: ID!) {
    backtestProgress(id: $id)
  }
`;

export type GetPortfolioDailyLogData = {
  portfolioDailyLog?: PortfolioDailyLog[];
};

export const PORTFOLIO_DAILY_LOG = gql`
  query PortfolioDailyLog($range: Int) {
    portfolioDailyLog(range: $range) {
      id
      logDate
      usdValue
      btcValue
      updatedAt
    }
  }
`;

export type GetColumnsPresetsData = {
  columnsPresets?: ColumnsPreset[];
};

export const GET_COLUMNS_PRESETS = gql`
  query GetColumnsPresets {
    columnsPresets {
      id
      name
      columns
    }
  }
`;

export const INVOICE_SUBSCRIPTION = gql`
  subscription InvoiceSubscription {
    invoiceEvents {
      invoiceId
      status
    }
  }
`;

export const PRODUCT_SUBSCRIPTION_EVENTS = gql`
  subscription ProductSubscriptionEvent {
    productSubscriptionEvents {
      subscriptionId
      status
    }
  }
`;

export type GetAnnouncementData = {
  announcement?: Announcement;
};

export const ANNOUNCEMENT = gql`
  subscription Announcement {
    announcement {
      id
      message
      type
      createdAt
      endTime
      isActive
    }
  }
`;

export const GET_ANNOUNCEMENT = gql`
  query GetAnnouncement {
    announcement {
      id
      message
      type
      createdAt
      endTime
      isActive
    }
  }
`;

export type GetExecutionNotesData = {
  execution?: Pick<Execution, "id" | "notes">;
};

export const GET_EXECUTION_NOTES = gql`
  query GetExecutionNotes($executionId: ID!) {
    execution(id: $executionId) {
      id
      notes
    }
  }
`;

export type GetExecutionOrdersData = {
  execution?: Pick<
    Execution,
    | "id"
    | "exchange"
    | "currencyPair"
    | "currencyPairDetails"
    | "measurements"
    | "orders"
    | "syndicationId"
  >;
};

export type GetExecutionOrdersVars = {
  executionId: ID;
  first?: number;
  after?: string;
  sortDirection?: SortDirection;
};

export const GET_EXECUTION_ORDERS = gql`
  query GetExecutionOrders(
    $executionId: ID!
    $first: Int
    $after: String
    $sortDirection: SortDirection
  ) {
    execution(id: $executionId) {
      id
      exchange
      currencyPair
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      measurements {
        ...TradesMeasurementData
      }
      orders(first: $first, after: $after, sortDirection: $sortDirection) {
        edges {
          cursor
          node {
            ...OrderMainData
            ...OrderPreCommissionData
            ...OrderPostCommissionData
            currencyPairDetails {
              id
              exchange
              pair
              base
              quote
              settleCurrency
              positionCurrency
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
      syndicationId
    }
  }
  ${TRADES_MEASUREMENT_DATA}
  ${ORDER_MAIN_DATA}
  ${ORDER_PRE_COMMISSION_DATA}
  ${ORDER_POST_COMMISSION_DATA}
`;

export const GET_MOST_POPULAR_SYNDICATIONS = gql`
  query MostPopularSyndications($first: Int, $rangeDays: Int) {
    mostPopularSyndications(first: $first) {
      edges {
        cursor
        node {
          ...PublicSyndicationListData
          performance(rangeDays: $rangeDays)
          analysisWithRange(rangeDays: 30) {
            ...AnalysisData
          }
          owner {
            firstName
            lastName
            companyName
            nickname
          }
        }
      }
    }
  }
  ${PUBLIC_SYNDICATION_LIST_DATA}
  ${ANALYSIS}
`;

export const GET_MOST_POPULAR_CREATORS = gql`
  query MostPopularCreators($first: Int) {
    mostPopularCreators(first: $first) {
      edges {
        cursor
        node {
          id
          nickname
        }
      }
    }
  }
`;

export type GetPortfolioRealizedProfitsLogData = {
  me?: Pick<User, "id"> & {
    portfolioRealizedProfitsLog?: PortfolioRealizedProfitsLog[];
  };
};

export const GET_PORTFOLIO_REALIZED_PROFITS_LOG = gql`
  query PortfolioRealizedProfitsLog {
    me {
      portfolioRealizedProfitsLog {
        logDate
        assetsUnderManagement
        totalAllocationInDollars
        totalAllocation
        totalRealizedProfitability
        totalRealizedProfits
        totalRealizedProfitsCarryForward
        totalAllocationCarryForward
        totalRealizedProfitabilityCarryForward
      }
    }
  }
`;

export type GetExecutionRealizedProfitsLogData = {
  execution?: Pick<
    Execution,
    | "id"
    | "name"
    | "scriptDetails"
    | "syndication"
    | "type"
    | "status"
    | "runNumber"
  > & {
    currencyPairDetails?: CurrencyPairDetails;
    realizedProfitsLog?: PortfolioRealizedProfitsLog[];
    analysis?: Analysis;
  };
};

export const GET_EXECUTION_REALIZED_PROFITS_LOG = gql`
  query ExecutionRealizedProfitsLog($id: ID!, $rangeDays: Int) {
    execution(id: $id) {
      id
      name
      scriptDetails {
        id
        scriptId
        revisionId
        parameters {
          fieldName
          readableName
        }
        name
        version
        isLatest
        createdAt
      }
      syndication {
        name
      }
      type
      status
      runNumber
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      realizedProfitsLog {
        logDate
        assetsUnderManagement
        totalAllocationInDollars
        totalAllocation
        totalRealizedProfitability
        totalRealizedProfits
        totalRealizedProfitsCarryForward
        totalAllocationCarryForward
        totalRealizedProfitabilityCarryForward
      }
      analysis(rangeDays: $rangeDays) {
        ...AnalysisData
      }
    }
  }
  ${ANALYSIS}
`;

export type GetBotRealizedProfitsLogData = {
  publicSyndication?: Pick<PublicSyndication, "id" | "name"> & {
    currencyPairDetails?: CurrencyPairDetails;
    analysis?: Analysis;
  };
};

export const GET_BOT_REALIZED_PROFITS_LOG = gql`
  query BotRealizedProfitsLog($id: ID!, $rangeDays: Int) {
    publicSyndication(id: $id) {
      id
      name
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      analysis(rangeDays: $rangeDays) {
        ...AnalysisData
      }
    }
  }
  ${ANALYSIS}
`;

export type GetBotBacktestRealizedProfitsLogData = {
  publicSyndication?: Pick<PublicSyndication, "id" | "name"> & {
    currencyPairDetails?: CurrencyPairDetails;
    backtestAnalysis?: Analysis;
  };
};

export const GET_BOT_BACKTEST_REALIZED_PROFITS_LOG = gql`
  query BotBacktestRealizedProfitsLog($id: ID!) {
    publicSyndication(id: $id) {
      id
      name
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      backtestAnalysis {
        ...AnalysisData
      }
    }
  }
  ${ANALYSIS}
`;

export type GetSharedExecutionRealizedProfitsLogData = {
  sharedExecution?: Pick<
    SharedExecution,
    "shareToken" | "name" | "type" | "status" | "scriptName"
  > & {
    realizedProfitsLog?: PortfolioRealizedProfitsLog[];
    analysis?: Analysis;
  };
};

export const GET_SHARED_EXECUTION_REALIZED_PROFITS_LOG = gql`
  query SharedExecutionRealizedProfitsLog($shareToken: ID!, $rangeDays: Int) {
    sharedExecution(shareToken: $shareToken) {
      shareToken
      name
      type
      status
      scriptName
      realizedProfitsLog {
        logDate
        assetsUnderManagement
        totalAllocationInDollars
        totalAllocation
        totalRealizedProfitability
        totalRealizedProfits
        totalRealizedProfitsCarryForward
        totalAllocationCarryForward
        totalRealizedProfitabilityCarryForward
      }
      analysis(rangeDays: $rangeDays) {
        ...AnalysisData
      }
    }
  }
  ${ANALYSIS}
`;

export type GetSubscriberRealizedProfitsLogData = {
  publicSyndication?: Pick<PublicSyndication, "id"> & {
    currencyPairDetails?: CurrencyPairDetails;
    subscriberRealizedProfitsLog?: PortfolioRealizedProfitsLog[];
  };
};

export const GET_SUBSCRIBER_REALIZED_PROFITS_LOG = gql`
  query SubscriberRealizedProfitsLog($id: ID!) {
    publicSyndication(id: $id) {
      id
      currencyPairDetails {
        id
        exchange
        pair
        base
        quote
        settleCurrency
        positionCurrency
      }
      subscriberRealizedProfitsLog {
        logDate
        assetsUnderManagement
        totalAllocationInDollars
        totalAllocation
        totalRealizedProfitability
        totalRealizedProfits
        totalRealizedProfitsCarryForward
        totalAllocationCarryForward
        totalRealizedProfitabilityCarryForward
      }
    }
  }
`;

export type GetMyPromoterDetailsData = {
  me?: Pick<User, "promoterDetails">;
};

export const GET_MY_PROMOTER_DETAILS = gql`
  query GetMyPromoterDetails {
    me {
      ...UserPromoterData
    }
  }
  ${USER_PROMOTER_DATA}
`;

export type VerifyRecaptchaData = {
  verifyRecaptcha?: VerifyRecaptcha;
};

export const VERIFY_RECAPTCHA_TOKEN = gql`
  query VerifyRecaptcha($token: String!) {
    verifyRecaptcha(token: $token) {
      success
      challengeTimestamp
      hostname
    }
  }
`;
