import React, {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { ID, UserTier } from "../graphql/schema";
import { useUserContext } from "./UserContext";
import invariant from "invariant";

export type SidebarPage =
  | "general"
  | "exchanges"
  | "security"
  | "subscriptions"
  | "billing"
  | "email_preferences"
  | "referrals";

interface Context {
  innerPage: string;
  setInnerPage(innerPage: string): void;
  selectedPlan: UserTier;
  setSelectedPlan(plan: UserTier): void;
  updatedCredentials: boolean;
  setUpdatedCredentials(updated: boolean): void;
  photo: string | undefined;
  setPhoto(photo: string | undefined): void;
  selectedInvoiceId: ID | undefined;
  setInvoiceId: (invoiceId: ID | undefined) => void;
}

const AccountSettingsContext = createContext<undefined | Context>(undefined);

export function useAccountSettingsContext() {
  const context = useContext(AccountSettingsContext);
  invariant(
    context != null,
    "Component is not a child of AccountSettingsContext provider",
  );
  return context;
}

interface Props {
  children: ReactNode;
}

export const AccountSettingsContextProvider = ({ children }: Props) => {
  const { user } = useUserContext();

  const [innerPage, setInnerPage] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [updatedCredentials, setUpdatedCredentials] = useState(false);
  const [photo, setPhoto] = useState<string | undefined>(undefined);
  const [selectedInvoiceId, setInvoiceId] = useState<ID | undefined>(undefined);

  const onUserPhotoLoad = useCallback(
    (e) => {
      if (user?.profilePhotoUrl) setPhoto(user.profilePhotoUrl);
    },
    [setPhoto, user],
  );

  const onUserPhotoError = useCallback(
    (e) => {
      setPhoto(undefined);
    },
    [setPhoto],
  );

  // check if user has a photo here so that it doesn't show the empty photo state for a second
  useEffect(() => {
    if (user?.profilePhotoUrl) {
      const userPhoto = new Image();
      userPhoto.onload = onUserPhotoLoad;
      userPhoto.onerror = onUserPhotoError;
      userPhoto.src = user.profilePhotoUrl;
    }
  }, [user, onUserPhotoLoad, onUserPhotoError]);

  return (
    <AccountSettingsContext.Provider
      value={{
        innerPage,
        setInnerPage,
        selectedPlan,
        setSelectedPlan,
        updatedCredentials,
        setUpdatedCredentials,
        photo,
        setPhoto,
        selectedInvoiceId,
        setInvoiceId,
      }}
    >
      {children}
    </AccountSettingsContext.Provider>
  );
};

export default AccountSettingsContext;
