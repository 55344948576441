import gql from "graphql-tag";
import {
  PAYMENT_SOURCE_DATA,
  INVOICE_DATA,
  BILLING_SUBSCRIPTION_DATA,
} from "../fragments/payments";
import {
  PaymentSource,
  Invoice,
  UserSubscription,
  BillingSubscriptionConnection,
  InvoiceConnection,
} from "../schema/payments";

export const GET_PAYMENT_SOURCE = gql`
  query PaymentSource {
    paymentSource(type: CREDIT_CARD) {
      ...PaymentSourceData
    }
  }
  ${PAYMENT_SOURCE_DATA}
`;
export type GetPaymentSourceData = { paymentSource?: PaymentSource };

export const GET_INVOICES = gql`
  query GetInvoices {
    invoices {
      ...Invoice
    }
  }
  ${INVOICE_DATA}
`;
export type GetInvoicesData = { invoices: Invoice[] };

export const GET_USER_SUBSCRIPTION = gql`
  subscription UserSubscription($subscriptionId: ID!) {
    userSubscription(subscriptionId: $subscriptionId) {
      subscriptionId
      status
    }
  }
`;

export type GetUserSubscription = { userSubscription: UserSubscription };

export const GET_MY_INVOICES = gql`
  query GetMyInvoices($first: Int, $after: String, $filter: InvoiceFilter) {
    me {
      id
      invoices(first: $first, after: $after, filter: $filter) {
        edges {
          cursor
          node {
            ...Invoice
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
  ${INVOICE_DATA}
`;

export type GetMyInvoicesData = {
  me?: {
    invoices?: InvoiceConnection;
  };
};

export const GET_MY_SUBSCRIPTIONS = gql`
  query GetMySubscriptions(
    $first: Int
    $after: String
    $filter: BillingSubscriptionFilter
  ) {
    me {
      id
      subscriptions(first: $first, after: $after, filter: $filter) {
        edges {
          cursor
          node {
            ...BillingSubscriptionData
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
  ${BILLING_SUBSCRIPTION_DATA}
`;

export type GetMySubscriptionsData = {
  me?: {
    subscriptions?: BillingSubscriptionConnection;
  };
};
